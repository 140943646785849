<template>
    
    <!-- zamenjava gesla -->
    <v-layout row wrap align-center justify-center fill-height>
        <v-flex xs12 sm12 md12 lg12 xl12>
            <v-form id="form_user_password_update" ref="form_user_password_update" v-model="form" lazy-validation>
                <v-card>
                    <v-card-title>
                            <span class="subtitle-1"><v-icon left>mdi-lock-reset</v-icon> Zamenjava uporabniškega gesla</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="toggleCardContent()">
                                <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                    </v-card-title>
                    <v-divider v-show="card1"></v-divider>
                    <v-card-text v-show="card1">
                        <!-- backend validation messages -->
                        <form-validation-messages :messages="apiPasswordValidationMessages">
                        </form-validation-messages>

                        <v-text-field
                            v-model="new_password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Novo geslo"
                            hint=""
                            counter
                            @click:append="show1 = !show1"
                        ></v-text-field>

                        <v-text-field
                            v-model="new_password_confirmation"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min, passwordMatch]"
                            :type="show2 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Ponovno vpišite novo geslo"
                            hint="Geslo naj vsebuje vsaj 6 unakov"
                            counter
                            @click:append="show2 = !show2"
                            @keyup.enter="updatePassword()"
                        ></v-text-field>
                        
                    </v-card-text>
                    <v-divider v-show="card1"></v-divider>
                    <v-card-text v-show="card1"  v-if="$vuetify.breakpoint.xsOnly">
                        <v-btn :disabled="loading" @click="updatePassword()" color="success" block outlined style="margin-bottom:10px;"><v-icon left>mdi-arrow-right</v-icon>Posodobi geslo</v-btn>
                        <v-btn :disabled="loading" @click="cancelPasswordReset()" color="error" block outlined><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                    </v-card-text>
                    <v-card-actions v-show="card1" v-else>
                        <v-btn :disabled="loading" @click="updatePassword()" color="success" text><v-icon left>mdi-arrow-right</v-icon>Posodobi geslo</v-btn>
                        <v-btn :disabled="loading" @click="cancelPasswordReset()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                    </v-card-actions>
                    <div style="min-height:5px;"></div>
                </v-card>
            </v-form>
        </v-flex>
    </v-layout>
    
</template>

<script>

const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')

export default {
    components: {
        FormValidationMessages
    },

    name: 'update-user-password',
    props: ['uuid'],

    data: () => ({
        form: false,
        card1: true,
        show1:false,
        show2:false,
        loading: false,
        new_password: '',
        new_password_confirmation: '',
        backendMessages: [],
        backendErrorMessages: [],
        backendPasswordValidationMessages:[],

        rules: {
          required: value => !!value || 'Vnos gesla je obvezen',
          min: v => v.length >= 5 || 'Geslo mora vsebovati vsaj 5 znakov'
        },
    }),

    watch: {

    },

    computed: {
        apiPasswordValidationMessages() {
            return this.backendPasswordValidationMessages
        }
    },

    methods: {
        updatePassword() {
            this.backendPasswordValidationMessages = []
            let vm = this;
            if(this.$refs.form_user_password_update.validate()) {
                vm.loading = true
                //window.console.log("OK")
                vm.$store.commit('SET_PROGRESS', true)
                vm.$store.commit('SET_LOADER', true)
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev gesla je v teku...')
                vm.$store.dispatch('ADMIN_USER_ACCOUNT_PASSWORD_UPDATE', { password: vm.new_password, password_confirmation: vm.new_password_confirmation, uuid: vm.uuid  })
                .then(response => {
                    //window.console.log(response.data)
                    //window.console.log(vm.email)
                    vm.$store.commit('SET_USER_PW_RESET', 1);

                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Zamenjava gesla je uspela.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.cancelPasswordReset()
                })
                .catch(error => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri zamenjavi gesla je prišlo do težave")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)

                    if(error.response.status === 422) {
                        for (const property in error.response.data.errors) {
                        //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                        var propertyData = error.response.data.errors[property];
                        //window.console.log(propertyData);
                        propertyData.forEach(message => {
                            vm.backendPasswordValidationMessages.push(message);
                        })

                        //window.console.log(vm.backendPasswordValidationMessages)

                        /*vm.$vuetify.goTo('#form_user_password_update', {
                            duration: 500,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })*/
                    }
                    }
                })
                .finally(() =>{
                    vm.$store.commit('SET_LOADER', false)
                    setTimeout(() => {
                        vm.$store.commit('SET_PROGRESS', false)
                    }, 600)
                    vm.loading = false
                })
            }
            else {
                vm.$vuetify.goTo('#form_user_password_update', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }
        },

        cancelPasswordReset() {
            this.$refs.form_user_password_update.resetValidation()
            this.current_password = ''
            this.new_password = ''
            this.new_password_confirmation = ''
            this.backendPasswordValidationMessages = []
            /*this.$vuetify.goTo('#form_user_password_update', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
            })*/
        },

        passwordMatch() {
            if(this.new_password !== this.new_password_confirmation) {
                return "Gesli se ne ujemata"
            }

            return true
        },

        toggleCardContent() {
            this.card1 = !this.card1
        }
    },

    created() {

    }, 

    mounted() {
        //window.console.log("### UpdateUserPassword@mounted ###")
        //window.console.log("User uuid: " + this.uuid)
    },

    beforeDestroy() {

    },

    destroyed() {

    }
}

</script>

<style scoped>

</style>